.container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 32px;
}

.reverse {
  flex-direction: row-reverse;
}

.imageContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: flex-start;
  flex-shrink: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.image {
  width: 100%;
  max-width: 364px;
  height: auto;
}

.textContent {
  display: flex !important;
  flex-direction: column !important;
  justify-content: flex-start;
  gap: 16px;
  padding: 16px;
}

.title {
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.5;
  margin: 0;
}

.text {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.8;
  margin: 0;
}
