.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px -6px 11px rgba(0, 0, 0, 0.02);
  cursor: pointer;
  height: 130px;
  width: 100%;
  text-align: center;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
}

.card:hover {
  background-color: #005ea9;
  color: #ffffff;
  transform: scale(1.05);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
}

.card span {
  color: #202020;
}

.card:hover span {
  color: #ffffff;
}

.icon {
  font-size: 40px;
  margin-bottom: 8px;
  color: #005ea9;
  transition: color 0.3s;
}

.card:hover .icon {
  color: #ffffff;
}

.title {
  font-family: Roboto Serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.46000000834465027px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #202020;
}

.title:hover {
  color: #005ea9;
}
